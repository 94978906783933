
import {
  Component,
  Vue
} from 'vue-property-decorator'
import BargeService from '../services/barge-service'

  @Component({
    name: 'Inventory',
    components: {}
  })
export default class Inventory extends Vue {
    protected isTableBusy = false
    protected lines: any = []
    protected barges: any = []
    protected barge: any = {}

    protected fields = [{
      key: 'index',
      label: '#',
      thStyle: {
        width: '5%'
      },
      class: 'text-right'
    },
    {
      key: 'type',
      label: 'Type',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'balance_quantity',
      label: 'Remain on Board (ROB)',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'modified_date',
      label: 'Modified Date',
      thStyle: {
        width: '15%'
      }
    }
    ]

    created () {
      this.boot()
    }

    protected boot () {
      this.populateBarges()
    }

    protected async populateBarges () {
      const response = await BargeService.getBarges()
      response.data.forEach((barge) => {
        this.barges.push({
          value: {
            id: barge.id,
            name: barge.name
          },
          text: barge.name
        })
      })
    }

    protected populateInventoryData () {
      this.isTableBusy = true
      BargeService.getInventoryData(this.barge.id).then((response) => {
        this.isTableBusy = false
        this.lines = response.data
      })
    }
}

