import APIService from '@/services/api-service'
const resource = '/barge'

class TransferService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getBarges () {
    return this.connector.get(`${resource}/names`)
  }

  public getInventoryData (bargeId) {
    return this.connector.get(`${resource}/inventory/${bargeId}`)
  }
}

export default new TransferService()
